import { useEffect, useRef, useState } from "react";
import { NavigationSteps } from "../../tp-core-types/NavigationSteps";
import NavigationHeader from "../navigation-header/NavigationHeader";
import PreviousNextButtonHeader from "../previous-next-button-header/PreviousNextButtonHeader";
import plusSign from "../../assets/plus_sign.svg";
import "./EducationInfo.scss";
import TPCoreAPI from "../../tp-core-api/TPCoreAPI";
import { HcpDegreeType, HcpDegreeTypes } from "../../tp-core-types/HcpDegreeTypes";
import { HcpDegreeField, HcpDegreeFields } from "../../tp-core-types/HcpDegreeFields";
import EducationInfoRecord from "./EducationInfoRecord";
import { HcpDegree } from "../../tp-core-types/HcpDegree";
import { HcpDegreeSaveResult } from "../../tp-core-types/HcpDegreeSaveResult";
import { HcpWebApplicationCompletedStep } from "../../tp-core-types/HcpWebApplicationCompletedStep";


export default function EducationInfo() {
    
    const [degreeTypes, setDegreeTypes] = useState<HcpDegreeType[]>();
    const [degreeFields, setDegreeFields] = useState<HcpDegreeField[]>();
    const [newRecordDisabled, setNewRecordDisabled] = useState<boolean>(true);
    const [educationRowData, setEducationRowData] = useState<HcpDegree[]>([] as HcpDegree[]);
    const educationRowDataPreviousLength = useRef<number>(0);

    async function getExistingDegrees() {
        const response = await TPCoreAPI.getHcpDegrees();

        if(response && response.degrees && response.degrees.length > 0) {
            setEducationRowData(response.degrees)
            setNewRecordDisabled(false);
        }
        else {
            const emptyForm = {
                school: "",
                location: "",
                degreeDate: "",
                degreeId: null,
                degreeTypeId: 0,
                degreeFieldId: 0,
                deleted: false,
                verified: false,
                degreeField: null,
                degreeType: null
            } as HcpDegree
            setEducationRowData([emptyForm])
        }
    }

    async function getTypesAndFields() {
        const dTypes: HcpDegreeTypes = await TPCoreAPI.getHcpDegreeTypes();
        setDegreeTypes(dTypes.degreeTypes);

        const dFields: HcpDegreeFields = await TPCoreAPI.getHcpDegreeFields();
        setDegreeFields(dFields.degreeFields);
    }

    function createNewRecord() {
        setNewRecordDisabled(true);
        const newEducationRowData = [...educationRowData];
        newEducationRowData.push({
            school: "",
            location: "",
            degreeDate: "",
            degreeId: null,
            degreeTypeId: 0,
            degreeFieldId: 0,
            deleted: false,
            verified: false,
            degreeField: null,
            degreeType: null
        } as HcpDegree);
        setEducationRowData(newEducationRowData);
    }

    async function updateRecord(index: number, data: HcpDegree){
        const newEducationRowData = [...educationRowData];
        newEducationRowData[index] = data;
        const result : HcpDegreeSaveResult = await TPCoreAPI.postHcpDegree(data);
        newEducationRowData[index] = {...data, degreeId: result.degreeId};
        setEducationRowData(newEducationRowData);
    }

    async function deleteRecord(index: number, data :HcpDegree){
        if( data.degreeId !== null )
        {
            data.deleted = true;
            await TPCoreAPI.postHcpDegree(data);
        }
        const newEducationRowData = [...educationRowData];
        newEducationRowData.splice(index, 1);
        setEducationRowData(newEducationRowData);
    }

    async function setEducationStepCompleted() {
        const completedStep: HcpWebApplicationCompletedStep = {
            StepName: "Education",
            StepComplete: true
        }
        await TPCoreAPI.postWebApplicationStep(completedStep)
    }

    function scrollToTop() {
        const titles = document.getElementsByClassName('education-info-record-header-text');
        const newTitle = titles[titles.length-1];
        if (newTitle === undefined) return;
        
        setTimeout(()=>newTitle.scrollIntoView({block: 'center'}), 0);
    }

    useEffect(()=>{
        getExistingDegrees();
        getTypesAndFields();
    },[])

    useEffect(() => { 
        if(educationRowData.length > educationRowDataPreviousLength.current) {
            scrollToTop();
        }
        educationRowDataPreviousLength.current = educationRowData.length;
    }, [educationRowData.length]);


    return (
        <div id="education-info">
            <NavigationHeader selectedStep={NavigationSteps.Education}  showNavigationBar={true} completedSteps={()=>null} />
                <div className="application-header">
                    <PreviousNextButtonHeader
                    selectedStep={NavigationSteps.Education}
                    isFormComplete={!newRecordDisabled}
                    callbackOnSave={() => setEducationStepCompleted()} stepsCompleted={undefined}                    />
                    <div className="title">
                        <div >Education</div>
                        <button className="new-record-button" hidden={newRecordDisabled} disabled={newRecordDisabled} onClick={() => createNewRecord()}>
                            <div className="new-record-button-content">
                                <img src={plusSign} alt=""/>
                                <div>New Record</div>
                            </div>
                        </button>
                    </div>
                </div>
                <div data-testid="education-container" className="application-page-container">
                    <div className="education-info-list">
                            {educationRowData && degreeFields && degreeTypes &&
                                educationRowData.map((e,i)=> 
                                    <EducationInfoRecord 
                                    key={e.degreeId !== null ? e.degreeId : "new component"} 
                                    recordNumber={i+1} 
                                    updateRecord={(data) => updateRecord(i, data)} 
                                    deleteRecord={(data) => deleteRecord(i, data)}
                                    setNewRecordDisabled={setNewRecordDisabled} 
                                    degreeData={e} 
                                    degreeFields={degreeFields} 
                                    degreeTypes={degreeTypes}
                                    numberOfRecords={educationRowData.length}
                                />
                               )
                            }
                        </div>
                </div> 
            </div>        
    )
}

