import { useNavigate } from "react-router-dom";
import { NavigationSteps } from "../../tp-core-types/NavigationSteps";
import NavigationHeader from "../navigation-header/NavigationHeader";
import { BuildNavigationStepDictionary } from "../navigation-header/NavigationStepDictionary";
import TPCoreAPI from "../../tp-core-api/TPCoreAPI";
import { useContext, useEffect, useState } from "react";
import FeatureFlagContext from "../../tp-core-types/FeatureFlagsModel";
import "./SkillsChecklistSummary.scss";

export default function SkillsChecklistSummary() {
    const navigate = useNavigate()
    const [userEmail, setUserEmail] = useState<string | null>(null);

    const ff = useContext(FeatureFlagContext);

    const isMobile = window.matchMedia("(max-width: 801px)").matches;


    useEffect(() => {
        updateEmail()
    }, [])

    const updateEmail = async () => {
        const hcpProfileData = await TPCoreAPI.getHcpProfileData()
        setUserEmail(hcpProfileData.email);
    }


    const handleClick = async () => {
        const stepDictionary = BuildNavigationStepDictionary(ff?.featureFlag?.featureFlag_HcpApplication_SkillsChecklist_IsEnabled, ff?.featureFlag?.featureFlag_Terms_First);
        const next = stepDictionary[NavigationSteps.Skills_Checklist_Summary].next;
        if ( next != null ) {
            const path = stepDictionary[next].path;
            navigate(path);
        }
        TPCoreAPI.postWebApplicationStep({StepComplete: true, StepName: "Skills_Checklist_Summary"});
    };
    return <div id="SkillsChecklistSummary">
        <NavigationHeader selectedStep={NavigationSteps.Skills_Checklist_Summary}  showNavigationBar={false} completedSteps={()=>null} />
        <div className="application-page-container">
            { userEmail &&
            <>
                <div className="skills-summary-title">To continue to the skills checklist, please go to your email:</div>
                <div className="email-info-container">
                    { isMobile && 
                        <div>
                            <div className="email-info-line"><div className="email-info-field">Your email address:</div><div className="email-info-value">{userEmail}</div></div>
                            <div className="email-info-line"><div className="email-info-field">Sender:</div><div className="email-info-value">HSH (support@hshcompetencies.com)</div></div>
                            <div className="email-info-line"><div className="email-info-field">Email Subject:</div><div className="email-info-value">[TRS Healthcare] New Assignments</div></div>
                        </div>
                    }
                    { !isMobile &&
                        <div className="email-info-content">
                            <div className="email-info-field">Your email address:</div>
                            <div className="email-info-value">{userEmail}</div>
                            <div className="email-info-field">Sender:</div>
                            <div className="email-info-value">HSH (support@hshcompetencies.com)</div>
                            <div className="email-info-field">Email Subject:</div>
                            <div className="email-info-value">[TRS Healthcare] New Assignments</div>
                        </div>
                    }
                </div>
                <div className="continue-button-container">
                    <div className="continue-application-button" onClick={handleClick}>Continue with Application</div>
                </div>
            </>
            }
        </div>
        
    </div>
}