import { useContext, useEffect, useState } from "react";
import { NavigationSteps } from "../../tp-core-types/NavigationSteps";
import NavigationHeader from "../navigation-header/NavigationHeader";
import PreviousNextButtonHeader from "../previous-next-button-header/PreviousNextButtonHeader";
import "./LicenseInfo.scss";
import { State, StatesAndProvinces } from "../../tp-core-types/PermanentAddressOptions";
import TPCoreAPI from "../../tp-core-api/TPCoreAPI";
import SingleBackgroundQuestion from "../background-questions/SingleBackgroundQuestion";
import { BackgroundAnswer } from "../background-questions/BackgroundAnswer";
import { BackgroundQuestion } from "../background-questions/BackgroundQuestion";
import { LicenseInfoModel } from "../../tp-core-types/LicenseInfoModel";
import UserInfoModel from "../../tp-core-types/UserInfoModel";

export default function LicenseInfo() {
    const defaultAnswer: BackgroundAnswer = {
        isAnswered: false,
        answer: false,
    };

    const [states, setStates] = useState<State[]>([]);
    const [stateId, setStateId] = useState<number| null>(null);
    const [licenseType, setLicenseType] = useState<string>("RN");
    const [licenseNumber, setLicenseNumber] = useState<string | null>(null);
    const [nameOnLicense, setNameOnLicense] = useState<string | null>(null);
    const [isCompact, setIsCompact] = useState<BackgroundAnswer>(defaultAnswer);
    const [isCompleted, setIsCompleted] = useState<boolean>(false);
    const [hasLicense, setHasLicense] = useState<boolean>(true);
    const [isAllied, setIsAllied] = useState<boolean>(false);

    const userInfoModel = useContext(UserInfoModel);

    const getStates = async () => {
        const statesAndProvinces: StatesAndProvinces = await TPCoreAPI.getStatesAndProvinces();
        if (statesAndProvinces["United States"]) {
            setStates(statesAndProvinces["United States"].sort((a, b) => a.name.localeCompare(b.name)));
        }
    };

    const getLicenseInfo = async() => {
        const license = await TPCoreAPI.getLicenseInfo();

        if (license.hasLicense == false) {
            setHasLicense(false);
        }else{
            setStateId(license.stateId);
            if (license.nursysLicenseType){
                setLicenseType(license.nursysLicenseType);
            }
            setLicenseNumber(license.licenseNumber);
            setNameOnLicense(license.nameOnLicense);
            setIsCompact({
                isAnswered: license.isCompact !== null ? true : false, 
                answer: license.isCompact !== null ? license.isCompact : false
            });
        }
    }
    
    useEffect(() => {
        getStates();
        getLicenseInfo();
    }, []);

    useEffect(() => {
        (stateId && licenseNumber&& nameOnLicense && isCompact.isAnswered) ? setIsCompleted(true) : setIsCompleted(false);
    }, [stateId, licenseNumber, nameOnLicense && isCompact.isAnswered])

    useEffect(() => {
        if ( userInfoModel.userInfo.discipline.isAllied === true)
        {
            setIsAllied(userInfoModel.userInfo.discipline.isAllied);
            setHasLicense(false)
        }
    },[userInfoModel.userInfo, userInfoModel.userInfo.discipline.isAllied])

    const q1: BackgroundQuestion = {
        questionNumber: 1,
        questionText: "Is this a Compact License?"
    };

    async function handleSave() : Promise<any> {
        let licenseInfo: LicenseInfoModel;        
        if(!hasLicense){
            licenseInfo = {
                stateId: null,
                nursysLicenseType: null,
                licenseNumber: null,
                nameOnLicense: null,
                isCompact: null,
                hasLicense: hasLicense
            }
            return TPCoreAPI.postLicenseInfo(licenseInfo);
        }
        else if (stateId && licenseNumber && nameOnLicense) {
            licenseInfo = {
                stateId: stateId,
                nursysLicenseType: licenseType,
                licenseNumber: licenseNumber,
                nameOnLicense: nameOnLicense,
                isCompact: isCompact.answer,
                hasLicense: hasLicense
    
            }
            return TPCoreAPI.postLicenseInfo(licenseInfo);
        }
        return Promise.resolve();
    }

    return (
        <>
            <div id="license-info">
                <NavigationHeader selectedStep={NavigationSteps.License_Info}  showNavigationBar={true} completedSteps={()=>null} />
                <div className="application-header">
                    <PreviousNextButtonHeader
                        selectedStep={NavigationSteps.License_Info}
                        isFormComplete={isCompleted || !hasLicense}
                        callbackOnSave={handleSave} stepsCompleted={null}                    />
                    <div className="title">Primary License Information</div>
                </div>
                <div className="application-page-container">
                    <div className="application-helper-text">We will run a Nursys search for nursing licenses. Please enter your primary nursing license information.</div>
                    <div className="check-allied-container">
                            <label htmlFor="check-allied">If you do not have a state license, or you are an Allied professional please check here.</label>
                            <input type="checkbox" className="application-checkbox" id="check-allied" checked={(!hasLicense? true : false) || isAllied} onChange={()=>{return}} onClick={() => setHasLicense(!hasLicense)}></input>
                        </div>
                    <div className="full-form">
                        <div className={!hasLicense? "form-container disabled" : "form-container"} data-testid="form-container">
                            <div className="inner-container">
                                <div className="label-input-container">
                                    <label htmlFor="license-select">License Type*</label>
                                    <select id="license-select" onChange={event => setLicenseType(event.target.value)} value={licenseType && hasLicense? licenseType : ""}>
                                        <option value={"RN"}>RN</option>
                                        <option value={"PN"}>PN</option>
                                        <option value={"CNP"}>APRN - CNP</option>
                                        <option value={"CRNA"}>APRN - CRNA</option>
                                        <option value={"CNS"}>APRN - CNS</option>
                                        <option value={"CNM"}>APRN - CNM</option>
                                    </select>
                                </div>
                                <div className="label-input-container">
                                    <label htmlFor="license-number">License Number*</label>
                                    <input id="license-number" onChange={event => setLicenseNumber(event.target.value)} value={licenseNumber && hasLicense? licenseNumber : ""}></input>
                                </div>

                            </div>

                            <div className="inner-container">
                                <div className="label-input-container">
                                    <label htmlFor="state"> State*</label>
                                    <select id="state" autoComplete='address-level1' onChange={event => { setStateId(parseInt(event.target.value))}} value={stateId && hasLicense? stateId : ""}>
                                        <option value="" key={"emptyOne"}></option>
                                        {states.map((state) => {
                                            return (
                                                <option key={state.stateId} value={state.stateId}>
                                                    {state.name}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                        
                                <div className="label-input-container">
                                    <label htmlFor="name-on-license">Name as it Appears on License*</label>
                                    <input autoComplete='name' id="name-on-license" onChange={event => setNameOnLicense(event.target.value)} value={nameOnLicense && hasLicense? nameOnLicense : ""}></input>
                                </div>
                            </div>

                            <div className="compact-license" id="single-question">
                                <SingleBackgroundQuestion
                                    backgroundQuestion={q1}
                                    setQuestionAnswer={(answer: BackgroundAnswer) => {
                                        setIsCompact(answer);
                                    }}
                                    currentState={hasLicense ? isCompact : defaultAnswer}
                                    isRequired={true}
                                    FollowUpQuestion={null}
                                    isCaliforniaResident={null}
                                />
                            </div>
                        </div> 
                        
                    </div>
                </div>
            </div>
        </>
    );
}
