import "./PreviousNextButtonHeader.scss";
import leftChevron from "../../assets/previous-button-chevron.svg";
import rightChevron from "../../assets/right-chevron-lg.svg";

import { NavigationSteps } from "../../tp-core-types/NavigationSteps";
import { BuildNavigationStepDictionary } from "../navigation-header/NavigationStepDictionary";
import UserInfoContext from "../../tp-core-types/UserInfoModel";
import { MouseEvent, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useError } from "../../utilities/context/useError";
import FeatureFlagContext from "../../tp-core-types/FeatureFlagsModel";
import TPCoreAPI from "../../tp-core-api/TPCoreAPI";

export type Props = {
    selectedStep: NavigationSteps;
    isFormComplete: boolean;
    callbackOnSave: () => Promise<any>;
    stepsCompleted: string[] | null | undefined;
};

export default function PreviousNextButtonHeader(props: Props) {
    const ff = useContext(FeatureFlagContext);
    const userContext = useContext(UserInfoContext);

    const navigationStepDictionary = BuildNavigationStepDictionary(ff.featureFlag.featureFlag_HcpApplication_SkillsChecklist_IsEnabled, ff?.featureFlag?.featureFlag_Terms_First);

    const isMobile = window.matchMedia("(max-width: 800px)").matches;
    const [previousStepText, setPreviousStepText] = useState<string | null>(null);
    const [nextStepText, setNextStepText] = useState<string | null>(null);
    const [isNextEnabled, setIsNextEnabled] = useState(false);

    const navigate = useNavigate();
    const { setError } = useError();
    const navigateToPrevious = () => {
        const previousStep = navigationStepDictionary[props.selectedStep].previous;
        if (previousStep) {
            const previousStepPath = navigationStepDictionary[previousStep].path;
            navigate(previousStepPath);
        }
    };

    const handleNext = async () => {
        try {
            await props.callbackOnSave();
            const nextStep = navigationStepDictionary[props.selectedStep].next;
            if (nextStep) {              
                const nextStepPath = navigationStepDictionary[nextStep].path;
                navigate(nextStepPath);                
            }
        } catch (e) {
            setError(new Error("It looks like something went wrong. Please try again."));
        }
    };

    useEffect(() => {
        const previous = navigationStepDictionary[props.selectedStep].previous;
        setPreviousStepText(previous ? navigationStepDictionary[previous].displayName : null);
    }, [props.selectedStep]);

    useEffect(() => {
        const next = navigationStepDictionary[props.selectedStep].next;
        setNextStepText(next ? navigationStepDictionary[next].displayName : null);
    }, [props.selectedStep]);

    useEffect(() => {
        setIsNextEnabled(props.isFormComplete);
    }, [props.isFormComplete]);

    async function handleSubmit(e: MouseEvent<HTMLButtonElement, unknown>) {
        e.currentTarget.disabled = true;
        setIsNextEnabled(false);
        try {
            await TPCoreAPI.saveSubmitApplication();
            userContext.refreshUserInfo();
            navigate("/dashboard");
        }
        catch {
            setIsNextEnabled(true);
            setError(new Error("It looks like something went wrong. Please try again."))
        }

    }

    return (
        <div id="PreviousNextButtonHeader">
            <div className="buttons-container">
                {previousStepText && !isMobile && (
                    <button className="previous-button" onClick={navigateToPrevious} data-testid="previous-button">
                        <img src={leftChevron} className="left-chevron" alt="previous-button"></img>
                        Previous
                    </button>
                )}

                {previousStepText && isMobile && (
                    <button className="previous-button" onClick={navigateToPrevious} data-testid="previous-button">
                        <img src={leftChevron} className="left-chevron" alt="previous-button"></img>
                        <div className="mobile-text-container">
                            <span className="bolded">Previous: </span>
                            <span>{previousStepText}</span>
                        </div>
                    </button>
                )}

                {!previousStepText && (<div className="previous-button-spacer"></div>)}

                {nextStepText && !isMobile && (
                    
                    props.stepsCompleted && isNextEnabled && props.selectedStep === NavigationSteps.Identification && ff.featureFlag.featureFlag_Terms_First 
                    ? 
                    <button
                            className="save-and-proceed-button"
                            onClick={handleSubmit}
                            data-testid="save-and-proceed-button"
                            disabled={!isNextEnabled}
                        >
                    <span>Submit Application</span>
                    {isNextEnabled && <img data-testid="right-chevron" src={rightChevron} alt="" />}
                    </button>
                    :
                        <button
                            className="save-and-proceed-button"
                            onClick={handleNext}
                            data-testid="save-and-proceed-button"
                            disabled={!isNextEnabled}
                        >
                            <span>Save and Proceed</span>
                            {isNextEnabled && <img data-testid="right-chevron" src={rightChevron} alt="" />}
                        </button>
                        
                )}

                {nextStepText && isMobile && (
                    <button
                        className="save-and-proceed-button"
                        onClick={handleNext}
                        data-testid="save-and-proceed-button"
                        disabled={!isNextEnabled}
                    >
                        <div className="mobile-text-container">
                            <span className="bolded">Next: </span>
                            <span>{nextStepText}</span>
                        </div>
                        {isNextEnabled && <img className="next-button-chevron" data-testid="right-chevron" src={rightChevron} alt="" />}
                    </button>
                )}


            </div>
        </div>
    );
}
