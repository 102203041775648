
import TPCoreAPI from "../../tp-core-api/TPCoreAPI";
import { useState, useEffect } from "react";
import { StatesAndProvinces, State } from "../../tp-core-types/PermanentAddressOptions";
import NavigationHeader from "../navigation-header/NavigationHeader";
import { NavigationSteps } from "../../tp-core-types/NavigationSteps";
import { useNavigate } from "react-router-dom";
import "./PermanentAddress.scss";
import PreviousNextButtonHeader from "../previous-next-button-header/PreviousNextButtonHeader";
import { PermanentAddressModel } from "../../tp-core-types/PermanentAddress";

export default function PermanentAddress() {
    const [statesAndProvinces, setStatesAndProvinces] = useState<State[]>();
    const [countries, setCountriesDropdown] = useState<string[]>()

    const [isFormComplete, setIsFormComplete] = useState<boolean>(false);

    const [country, setCountry] = useState<string>("United States");
    const [streetAddress, setStreetAddress] = useState<string>("");
    const [city, setCity] = useState<string>("");
    const [state, setState] = useState<string>("");
    const [zip, setZip] = useState<string>("");
    const [province, setProvince] = useState<string>("");
    const [postalCode, setPostalCode] = useState<string>("");

    const isUSA = (country: string) : boolean => {
        if(country.toUpperCase() === "UNITED STATES" ||
        country.toUpperCase() === "USA" ||
        country.toUpperCase() === "US") {
            return true;
        }
        else {
            return false;
        }
    }
    const getStatesAndProvinces = async () => {
        const sAndP:StatesAndProvinces = await TPCoreAPI.getStatesAndProvinces();
        
        setCountriesDropdown(Object.keys(sAndP));
        let theStatesAndProvinces: State[] = sAndP['United States'] || []
        theStatesAndProvinces = theStatesAndProvinces.concat(sAndP['Canada'] || []);
        setStatesAndProvinces(theStatesAndProvinces)
    };

    const getHcpAddress = async () => {
        const hcpAddress: PermanentAddressModel = await TPCoreAPI.getHcpAddress();
        setCountry(hcpAddress.country);
        setStreetAddress(hcpAddress.streetAddress);
        setCity(hcpAddress.city);
        if(hcpAddress.postalCode !== null)
            setPostalCode(hcpAddress.postalCode);
        if(hcpAddress.province !== null)
            setProvince(hcpAddress.province);
        if(hcpAddress.state !== null && hcpAddress.zipCode !== null) {
            setState(hcpAddress.state);
            setZip(hcpAddress.zipCode);
        }
    }

    useEffect(() => {
        getStatesAndProvinces()
        getHcpAddress();
    }, []);

    useEffect(()=> {
        
        const stateOrProvince = isUSA(country) ? state: province
        const zipOrPostalCode = isUSA(country) ? zip : postalCode

        if(country &&
            streetAddress &&
            city &&
            stateOrProvince &&
            zipOrPostalCode
        )
        {
            setIsFormComplete(true);
        }
        else {
            setIsFormComplete(false);
        }
  
    },[country,
        streetAddress,
        city,
        state,
        zip,
        province,
        postalCode
    ])

    const clearFields = () => {
        setCity("");
        setPostalCode("");
        setProvince("");
        setState("");
        setStreetAddress("");
        setZip("");
    };

    async function handleSave(): Promise<Response> {
        const permAddress :PermanentAddressModel = {
            country: country,
            streetAddress: streetAddress,
            city: city,
            state: state !== "" ? state : null,
            zipCode: zip !== "" ? zip : null,
            province: province !== "" ? province : null,
            postalCode: postalCode !== "" ? postalCode : null
        }
        
        return TPCoreAPI.postHcpPermenentAddress(permAddress);
    }

    const navigate = useNavigate();

    const isMobile = window.matchMedia("(max-width: 800px)").matches;

    return (
        <div id="PermanentAddress">
            <NavigationHeader selectedStep={NavigationSteps.Address}  showNavigationBar={true} completedSteps={()=>void{} } />
            <div className="application-header">
                <PreviousNextButtonHeader
                    selectedStep={NavigationSteps.Address}
                    isFormComplete={isFormComplete}
                    callbackOnSave={handleSave} stepsCompleted={null}                />
                
                <div className="title">
                    Permanent Address
                </div>
            </div>
            <div className="application-page-container">
                <div className="form">
                    <div className="country">
                        <label htmlFor="country-dropdown">Country*</label>
                        { countries && 
                            <select autoComplete="country" onChange={event => {setCountry(event.target.value); clearFields();}} name="Country" data-testid="country-dropdown" id="country-dropdown" value={country}>
                                {countries.map((country) => {
                                    return (
                                        <option key={country} value={country}>
                                            {" "}
                                            {country}{" "}
                                        </option>
                                    );
                                })}
                            </select>
                        }
                    </div>

                    <div className="street-address">
                        <label htmlFor="street-address-input">Street Address*</label>
                        <input autoComplete="street-address" onChange={event => setStreetAddress(event.target.value)} data-testid="street-address-input" id="street-address-input" value={streetAddress}></input>
                    </div >

                    <div className="city">
                        <label htmlFor="city-input">City*</label>
                        <input autoComplete="address-level2" onChange={event => setCity(event.target.value)} data-testid="city-input" id="city-input" value={city}></input>
                    </div>

                    <div className="state">
                        {(statesAndProvinces && isUSA(country)) &&
                        <>
                            <label htmlFor="state-dropdown">State*</label>
                            <select autoComplete='address-level1' onChange={event => setState(event.target.value)} name="State" data-testid="state-dropdown" id="state-dropdown" value={state}>
                                <option value="" key={"emptyState"}></option>
                                { 
                                statesAndProvinces.filter(state => state.countryName === "United States" ).map((state) => {
                                    return <option key={state.stateId} value={state.abbreviation}> {state.name} </option>
                                })
                                }
                            </select>
                        </>
                        }
                         {(statesAndProvinces && country === "Canada") &&
                         <>
                            <label htmlFor="province-dropdown">Province*</label>
                            <select autoComplete='address-level1' onChange={event => setProvince(event.target.value)} name="Province" data-testid="province-dropdown" id="province-dropdown" value={province}>
                                <option value="" key={"emptyProvince"}></option>
                                {                                 
                                statesAndProvinces.filter(state => state.countryName === "Canada" ).map((state) => {
                                    return <option key={state.stateId} value={state.abbreviation}> {state.name} </option>
                                })
                                }
                            </select>
                        </>
                        }   
                    </div>

                    <div className="zip">
                        { isUSA(country) &&
                            <>
                                <label htmlFor="zip-code-input">Zip Code*</label>
                                <input autoComplete="postal-code" onChange={event => setZip(event.target.value)} data-testid="zip-code-input" id="zip-code-input" value={zip}></input>
                            </>
                        } 
                        { country === "Canada" &&
                            <>
                                <label htmlFor="postal-code-input">Postal Code*</label>
                                <input autoComplete='postal-code' onChange={event => setPostalCode(event.target.value)} data-testid="postal-code-input" id="postal-code-input" value={postalCode}></input>
                            </>
                        }
                    </div>
                </div>
                </div>
            </div>
    );
}
