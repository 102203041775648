import "./NavigationHeader.scss";
import checkmark from "../../assets/checkmark_green.svg";
import { NavigationSteps } from "../../tp-core-types/NavigationSteps";
import TPCoreAPI from "../../tp-core-api/TPCoreAPI";
import { useContext, useEffect, useState } from "react";
import AppStatusTracker from "./AppStatusTracker";
import { useNavigate } from "react-router-dom";
import { AppStatusTrackerSize } from "./AppStatusTrackerSizes";
import FeatureFlagContext from "../../tp-core-types/FeatureFlagsModel";

export type Props = {
    selectedStep: NavigationSteps;
    showNavigationBar : boolean;
    completedSteps: (steps: NavigationSteps[]) => void | null;
};




export default function NavigationHeader(props: Props) {
    const [completedSteps, setCompletedSteps] = useState<NavigationSteps[]>();
    const isMobile = window.matchMedia("(max-width: 800px)").matches;
    const ff = useContext(FeatureFlagContext);
    useEffect(() => {
        GetCompletedSteps();
    }, []);

    const navigate = useNavigate();

    const GetCompletedSteps = async () => {
        const ourCompletedSteps = await TPCoreAPI.getCompletedHCPApplicationSteps();
        setCompletedSteps(ourCompletedSteps);
        props.completedSteps(ourCompletedSteps);
    };

    const GetHeaderItem = (navigationStep: NavigationSteps) => {

        if (navigationStep === props.selectedStep) {
            return <div className="navigation-header-item selected-item">{navigationStep.toString()}</div>;
        }

        if (completedSteps?.includes(navigationStep)) {
            return (
                <div className="completed-item-container">
                    <div>
                        <img className="header-icon" src={checkmark} alt=""></img>
                    </div>
                    <div className="navigation-header-item completed-item">{navigationStep.toString()}</div>
                </div>
            );
        }
        return <div className="navigation-header-item">{navigationStep.toString()}</div>;
    };

    const resumeLaterOnclick = () => {
        navigate("/dashboard");
    }

    return (
        <div id="NavigationContainer">
            <div className="navigation-application-status-background">
                <div className="navigation-application-status-container" data-testid="navigation-application-status-container">
                    <div className="application-text">Application</div>
                    <AppStatusTracker stepsComplete={completedSteps ?? []} size={AppStatusTrackerSize.Small} verbose={false}/>
                    <div className="resume-later-button-container">
                        <button data-testid='resume-later-button' className='resume-later-button' onClick={resumeLaterOnclick}>Finish Later</button>
                    </div>
                </div>
            </div>
            {!isMobile && completedSteps !== null && props.showNavigationBar && (
                <div data-testid="header-navigation-container" className="header-navigation-container">
                    
                    {ff.featureFlag.featureFlag_Terms_First && GetHeaderItem(NavigationSteps.Application_Documents)}
                    {GetHeaderItem(NavigationSteps.Background)}
                    {GetHeaderItem(NavigationSteps.Address)}
                    {GetHeaderItem(NavigationSteps.License_Info)}
                    {GetHeaderItem(NavigationSteps.Education)}
                    {GetHeaderItem(NavigationSteps.Employment)}
                    {GetHeaderItem(NavigationSteps.References)}
                    {GetHeaderItem(NavigationSteps.Certifications)}
                    {GetHeaderItem(NavigationSteps.Identification)}
                </div>
            )}
        </div>
    );
}
